
import { Vue, Options } from 'vue-class-component';
import { Address, Domain, Organisation } from '@/models';
import { DomainService, OrganisationService } from '@/services/api';
import {
  BaseTextInput,
  BaseButton,
  BaseSelect,
  BaseCard,
  CardHeader,
  Address as AddressAutocomplete
} from '@/lib/components';
import { ORGANISATION_DESCRIPTION_MAX_LENGTH, FEATURES, ORGANISATION_NAME_MAX_LENGTH } from '@/constants';
import { isFeatureFlagEnabledOnDomain } from '@/helpers/feature-flag.helper';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    organisationId: {
      type: String,
      required: true
    },
    organisation: {
      type: Object,
      required: true
    }
  },
  components: {
    CardHeader,
    BaseCard,
    AddressAutocomplete,
    BaseTextInput,
    BaseButton,
    BaseSelect
  }
})
export default class OrganisationDetailPage extends Vue {
  organisationId!: string;
  organisation!: Domain | Organisation;

  name: string = this.organisation.name || '';
  description: string = this.organisation.description || '';
  address: Address = (this.organisation as Organisation).address || {
    formatted_address: ''
  };

  shippingAddress: Address = (this.organisation as Organisation).shipping_address || {
    formatted_address: ''
  };

  support_email: string = (this.organisation as Organisation).support_email || '';

  errors: { [key: string]: Array<string> } = {};

  orgService = new OrganisationService();
  domainService = new DomainService();

  notificationStore = useNotificationStore();

  get shouldLimitNameLength(): boolean {
    const domain =
      this.orgType === 'organisations'
        ? (this.organisation as Organisation)?.domain
        : this.organisation as Domain;

    return this.orgType === 'organisations' && isFeatureFlagEnabledOnDomain(FEATURES.ORGANISATION_NAME_LIMIT, domain);
  }

  get nameLimit(): number | null {
    return this.shouldLimitNameLength ? ORGANISATION_NAME_MAX_LENGTH : null;
  }

  get descriptionLimit(): number {
    return ORGANISATION_DESCRIPTION_MAX_LENGTH;
  }

  get orgType(): string {
    return this.organisation.type;
  }

  validateDescription() {
    if (this.description.length > ORGANISATION_DESCRIPTION_MAX_LENGTH) {
      this.errors = {
        ...this.errors,
        description: [this.$t('platform.domain-admin.description-error', [ORGANISATION_DESCRIPTION_MAX_LENGTH]) as string]
      };
    } else if (this.errors.description && this.errors.description.length) {
      this.errors = {
        ...this.errors,
        description: []
      };
    }
  }

  validateName() {
    this.errors = {
      ...this.errors,
      name: (this.shouldLimitNameLength && this.name.length > ORGANISATION_NAME_MAX_LENGTH) ? [this.$t('platform.domain-admin.organisations.errors.name', [ORGANISATION_NAME_MAX_LENGTH]) as string] : []
    };
  }

  async save() {
    try {
      let organisation: Organisation | Domain;
      if (this.orgType === 'organisations') {
        organisation = await this.orgService.update(this.organisationId, {
          name: this.name,
          description: this.description,
          ...(this.address.formatted_address.length ? { address: this.address } : {}),
          ...(this.shippingAddress.formatted_address.length ? { shipping_address: this.shippingAddress } : {}),
          support_email: this.support_email
        });
      } else {
        organisation = await this.domainService.update(this.organisationId, {
          name: this.name,
          description: this.description
        });
      }
      this.$emit('update', { ...this.organisation, ...organisation });
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.org-unit.update-success')
      });
    } catch (error) {
      console.warn('Error updating Org', error);
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.org-unit.update-error')
        });
      }
    }
  }

  goBack() {
    this.$router.back();
  }
}
