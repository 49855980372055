import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-gray-900 text-lg font-semibold capitalize" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_AddressAutocomplete = _resolveComponent("AddressAutocomplete")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "space-y-8" }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(`platform.domain-admin.${_ctx.orgType}.settings.title`)), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseTextInput, {
          modelValue: _ctx.name,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            _ctx.validateName
          ],
          label: _ctx.$t('platform.common.name'),
          error: _ctx.errors.title ? _ctx.errors.title[0] : _ctx.errors.name ? _ctx.errors.name[0] : null,
          count: _ctx.nameLimit
        }, null, 8, ["modelValue", "label", "error", "count", "onUpdate:modelValue"]),
        _createVNode(_component_BaseTextInput, {
          modelValue: _ctx.description,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
            _ctx.validateDescription
          ],
          label: _ctx.$t('platform.common.description'),
          placeholder: _ctx.$t('platform.org-unit.add-description'),
          count: _ctx.descriptionLimit,
          error: _ctx.errors.description && _ctx.errors.description.length ? _ctx.errors.description[0] : null,
          "is-optional": ""
        }, null, 8, ["modelValue", "label", "placeholder", "count", "error", "onUpdate:modelValue"])
      ]),
      (_ctx.orgType === 'organisations')
        ? (_openBlock(), _createBlock(_component_AddressAutocomplete, {
            key: 0,
            modelValue: _ctx.address,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address) = $event)),
            label: _ctx.$t('platform.common.address'),
            placeholder: _ctx.$t('platform.common.address'),
            "is-optional": "",
            error: _ctx.errors['address.formatted_address'] ? _ctx.errors['address.formatted_address'][0] : null
          }, null, 8, ["modelValue", "label", "placeholder", "error"]))
        : _createCommentVNode("", true),
      (_ctx.orgType === 'organisations')
        ? (_openBlock(), _createBlock(_component_AddressAutocomplete, {
            key: 1,
            modelValue: _ctx.shippingAddress,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shippingAddress) = $event)),
            label: _ctx.$t('platform.org-unit.shipping-address'),
            placeholder: _ctx.$t('platform.org-unit.shipping-address'),
            "is-optional": "",
            error: _ctx.errors['shipping_address.formatted_address'] ? _ctx.errors['shipping_address.formatted_address'][0] : null
          }, null, 8, ["modelValue", "label", "placeholder", "error"]))
        : _createCommentVNode("", true),
      (_ctx.orgType === 'organisations')
        ? (_openBlock(), _createBlock(_component_BaseTextInput, {
            key: 2,
            modelValue: _ctx.support_email,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.support_email) = $event)),
            "is-optional": "",
            label: _ctx.$t('platform.org-unit.support-email'),
            error: _ctx.errors.support_email ? _ctx.errors.support_email[0] : null
          }, null, 8, ["modelValue", "label", "error"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_BaseButton, {
          size: "large",
          onClick: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_BaseButton, {
          size: "large",
          color: "ghost",
          class: "ml-4",
          onClick: _ctx.goBack
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}